<template>
  <div id="website">
    <Header ></Header>
    <Content></Content>
  </div>
</template>

<script>
import Header from '@/components/Header';
import Content from '@/components/Content';

export default {
  name: 'WebSite',
  components: {
    Header,
    Content
  },
  methods:{

  },
  mounted(){

  }
}
</script>

<style>

#website {
  height: 100%;
}
</style>
