<template>
  <div class="footer">
    <div class="service-container">
      <img class="wechat-service" src="../assets/wechat_service.jpg"/>
      <div class="phone-container">
        <p>联系电话:19901690746</p>
        <p>周一至周五 9:00-18:00</p>
      </div>
    </div>
     <span class="introduce" >©2020上海曲尺信息技术有限公司 ｜ <a href="https://beian.miit.gov.cn" target="_blank">版权所有沪ICP备2020034633号-1</a></span>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data(){
    return {

    }
  }
}
</script>

<style lang="less">
  .footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
    
    .service-container{
      width: 100%;
      max-width: 766px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      margin-bottom: 10px;
      // padding: 12px 0;
      
      .wechat-service{
        width: 140px;
        height: 140px;
        margin-right: 48px;
        padding: 2px;
        border: 1px solid #cccccc;
      }

      p {
        margin:0;
      }

      .phone-container{
        p:first-child {
          margin-bottom: 12px;
        }
      }
    }

    .introduce {
      width: 100%;
      text-align: center;
      position: absolute;
      bottom: 12px;
      color: #c4c3c4;
      font: 14px/14px "\5FAE\8F6F\96C5\9ED1";
      margin-top: 20px;
    }
  }
</style>