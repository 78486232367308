<template>
  <div class="header-container">
    <div class="logo-container">
      <img src="../assets/logo.png" >
      <h1>家易量</h1>
    </div>

    <div class="header-nav" v-if="show">
      <a @click.prevent="goHome">首页</a>
      <a @click.prevent="goInfo">详情</a>
      <a @click.prevent="goAboutUs">关于我们</a>
      <!-- <a @click.prevent="goDownload">下载</a> -->
      <!-- <a @click.prevent="goLogin">登录</a> -->
    </div>
    <div class="login-container"  v-else>
      <!-- <a @click.prevent="goLogin">登录</a> -->
    </div>
  </div>
</template>

<script>

import { mixinDevice } from '@/util/mixin';

export default {
  name: 'Header',
  mixins:[mixinDevice],
  data(){
    return {
      device : null,
    }
  },
  methods:{
    goHome(){
      // console.log("gohome")
      this.bus.$emit("on-slide-change",'home');
    },
    goInfo(){
      this.bus.$emit("on-slide-change",'info');
    },
    goAboutUs(){
      this.bus.$emit("on-slide-change",'about-us');
    },
    goDownload(){
      this.bus.$emit("on-slide-change",'download');
    },
    goLogin(){
      window.open('http://139.196.197.70/login','_blank');
    }
  },
  computed:{
    show(){
      return !this.isMobile();
    }
  },
  mounted(){

  }
}
</script>

<style lang="less" scoped>
  
  div{
    box-sizing: border-box;
  }

  .header-container{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 76px;
    padding: 0 60px;
    background: #ffffff;
    border-bottom: 1px solid #dedede;
    z-index: 20;
    
    .logo-container{
      display: flex;
      align-items: center;

      img{
        width: auto;
        height: 38px;
      }
      
      h1{
        font-size: 22px;
        margin-left: 8px;
      }
    }

    .header-nav{
      display: flex;
      align-items: center;

      a {
        text-decoration: none;
        color: #2d8cf0;
        cursor: pointer;
        margin-right: 24px;
        font-size: 16px;
      }

      // a:last-child{
      //   color: #2d8cf0;
      // }

      &:last-child{
        margin-right: 0;
      }

    }
  }

  @media screen and (max-width:750px){
    .header-container{
      justify-content: space-between;
      padding: 0 12px;
    }

    .login-container{
      margin-right: 20px;
      font-size: 16px;
      font-weight: bold;
    }
  }
</style>